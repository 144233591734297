import { NextPage } from 'next';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import ConnexionFlowLayout from '@components/ConnexionFlowLayout';
import VerifyEmail, { EMAIL_VERIFIED_QUERY_KEY } from '@components/VerifyEmail';
import { sendOnboardingEmail } from '@functions';
import { APP_NAME } from '@utils/consts/livv';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';
import useAlertSnackBar from '@utils/hooks/useAlertSnackBar';
import useSession from '@utils/hooks/useUserSession';

const withEmailVerification =
    <P extends object>(Component: NextPage<P>): NextPage<P> =>
    (props) => {
        const [successSnackBar, setSuccessMessage] = useAlertSnackBar({
            anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
            severity: 'success',
        });
        const { t } = useTranslation('common');
        const router = useRouter();
        const { user, isLoading: isAuthLoading } = useAuth();
        const { loading: isUserLoading, privileges } = useUser();
        const { isLoading: isUserSessionLoading } = useSession();

        useEffect(() => {
            if (user && user.emailVerified && router.query[EMAIL_VERIFIED_QUERY_KEY]) {
                setSuccessMessage(
                    t('emailVerification:verificationSuccessful', { appName: APP_NAME }),
                );
                window.history.replaceState(
                    null,
                    '',
                    router.asPath.replace(
                        new RegExp(`(\\?|\\&)${EMAIL_VERIFIED_QUERY_KEY}=true`),
                        '',
                    ),
                );
                sendOnboardingEmail({ uid: user.uid });
            }
            // Display message only once
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [user]);

        if (isUserLoading || isUserSessionLoading || isAuthLoading) {
            return null;
        }

        // Check privileges first to avoid falsy value for isEmailVerified
        // This is to prevent the VerifyEmail component from rendering when user is not logged in
        // and the isEmailVerified is undefined
        if (user && privileges && !privileges.isEmailVerified) {
            return (
                <ConnexionFlowLayout>
                    <VerifyEmail />
                </ConnexionFlowLayout>
            );
        }

        return (
            <>
                <Component {...props} />
                {successSnackBar}
            </>
        );
    };

export default withEmailVerification;
