import { Box, Button, Typography } from '@mui/material';
import TransText from 'next-translate/TransText';
import useTranslation from 'next-translate/useTranslation';
import { FC, useEffect, useState } from 'react';
import Alert from '@components/Alert';
import FullPageLoader from '@components/Loader/FullPageLoader';
import { APP_NAME } from '@utils/consts/livv';
import { useAuth } from '@utils/context/auth';
import useUser from '@utils/context/user';
import useAlertSnackBar from '@utils/hooks/useAlertSnackBar';

export const EMAIL_VERIFIED_QUERY_KEY = 'emailVerified';

interface VerifyEmailProps {
    fullWidthButton?: boolean;
}

const VerifyEmail: FC<VerifyEmailProps> = ({ fullWidthButton }) => {
    const { t } = useTranslation('emailVerification');
    const [url, setUrl] = useState<URL | null>(null);
    const emailVerificationError = url?.searchParams.get('emailVerificationError');
    const { sendEmailVerification, isLoading } = useAuth();
    const { info, loading } = useUser();
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [successSnackBar, setSuccessMessage] = useAlertSnackBar({ severity: 'success' });
    const [warningSnackBar, setWarningMessage] = useAlertSnackBar({ severity: 'warning' });

    useEffect(() => {
        if (window !== undefined) {
            setUrl(new URL(window.location.href));
        }
    }, []);

    const contentLines = t(
        'content',
        { userEmail: info?.email },
        { returnObjects: true },
    ) as string[];
    const tooltipLines = t(
        'tooltip',
        { appName: APP_NAME, userEmail: info?.email },
        { returnObjects: true },
    ) as string[];

    const handleSendEmail = async () => {
        setIsSendingEmail(true);
        const actionCodeSettings = {
            handleCodeInApp: false,
            url: `${window.location.href}?${EMAIL_VERIFIED_QUERY_KEY}=true`,
        };
        const success = await sendEmailVerification(actionCodeSettings);
        if (success) {
            setSuccessMessage(t('snackbar.success', { userEmail: info?.email }));
        } else {
            setWarningMessage(t('snackbar.warning'));
        }
        setIsSendingEmail(false);
    };

    if (isLoading || loading) return <FullPageLoader />;

    return (
        <>
            <Typography variant="h1">{t('title')}</Typography>
            {contentLines.map((line) => (
                <Typography key={line} sx={{ mt: 2 }}>
                    <TransText components={{ i: <i /> }} text={line} />
                </Typography>
            ))}
            <Box sx={{ backgroundColor: 'primary.light', borderRadius: 1, mt: 2, p: 2 }}>
                {tooltipLines.map((line) => (
                    <Typography key={line}>
                        <TransText components={{ i: <i /> }} text={line} />
                    </Typography>
                ))}
            </Box>
            {emailVerificationError && (
                <Alert severity="error" sx={{ mt: 3 }}>
                    {t('emailVerificationError')}
                </Alert>
            )}
            <Button
                data-test-id="send-verification-email-button"
                disabled={isSendingEmail}
                fullWidth={fullWidthButton}
                onClick={handleSendEmail}
                sx={{ mt: 3 }}
                variant="contained"
            >
                {t('resendMail')}
            </Button>
            {successSnackBar}
            {warningSnackBar}
        </>
    );
};

export default VerifyEmail;
